var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import RichEditor from "~/components/common/rich-editor.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { namespace } from "vuex-class";
import { Layout, Dependencies } from "~/core/decorator";
import { NoticeService } from "~/services/manage-service/notice.service";
import AddBulletin from "~/components/assist-work/dia-log/add-bulletin.vue";
var inrushCaseModule = namespace("inrush-case");
var BulletinManage = /** @class */ (function (_super) {
    __extends(BulletinManage, _super);
    function BulletinManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editorOption = {
            theme: "bubble",
            placeholder: ""
        };
        _this.loading = {
            state: false
        };
        // 当前选中行
        _this.currentNotice = null;
        _this.queryParamsModel = {
            // 关键字
            keyword: "",
            // 发布者
            name: "",
            // 类型
            type: ""
        };
        _this.dialog = {
            addBulletin: false
        };
        _this.undistributedModel = {};
        _this.dataSet = [];
        return _this;
    }
    BulletinManage.prototype.mounted = function () {
        this.refreshData();
    };
    BulletinManage.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.noticeService.findAllNotice(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
            // 默认显示第一条数据
            _this.currentNotice = _this.dataSet && _this.dataSet.length ? _this.dataSet[0] : {};
        });
    };
    /**
     * 新增
     */
    BulletinManage.prototype.addBulletinClick = function () {
        this.$router.push("/assist-work/bulletin-manage/add-bulletin");
    };
    // 选中行处理事件
    BulletinManage.prototype.onRowClick = function (row, event, column) {
        this.currentNotice = row;
    };
    BulletinManage.prototype.rowStyle = function (_a) {
        var row = _a.row, rowIndex = _a.rowIndex;
        if (this.currentNotice && row.id == this.currentNotice.id) {
            return { color: "#FF9666", cursor: "pointer" };
        }
        return { cursor: "pointer" };
    };
    /**
     * 排序选项触发
     */
    BulletinManage.prototype.handleCommand = function (command) {
        // 处理排序
        switch (command) {
            case "ascending":
                this.sortService.update("operateTime", "ascending");
                break;
            case "descending":
                this.sortService.update("operateTime", "descending");
                break;
        }
        this.refreshData();
    };
    __decorate([
        Dependencies(PageService)
    ], BulletinManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], BulletinManage.prototype, "sortService", void 0);
    __decorate([
        Dependencies(NoticeService)
    ], BulletinManage.prototype, "noticeService", void 0);
    BulletinManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                AddBulletin: AddBulletin,
                RichEditor: RichEditor
            }
        })
    ], BulletinManage);
    return BulletinManage;
}(Vue));
export default BulletinManage;
